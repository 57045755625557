export const firstNames = [
	"Uncle",
	"Papa",
	"Sir",
	"Cool",
	"Mrs",
	"Mr",
	"Love",
	"Doctor",
	"Mama",
	"Lucky",
	"Quick",
	"Unlucky",
	"Clever",
	"Freak",
	"Random",
	"House",
	"Slow",
	"Toilet",
	"Bouncy",
	"Sister",
	"Brother",
	"Foolish",
	"Fluffy",
	"Haunted",
	"Captain",
	"Jolly",
	"Lovely",
	"Lady",
];

export const lastNames = [
	"Student",
	"Cactus",
	"Boat",
	"Data",
	"Rabbit",
	"Fork",
	"Fast",
	"Slow",
	"Heat",
	"IQ",
	"Hacker",
	"Aimbot",
	"Snail",
	"Cow",
	"Lion",
	"House",
	"Police",
	"Flower",
	"Woman",
	"Man",
	"Boy",
	"Girl",
	"Street",
	"Steam",
	"Ice",
	"Fire",
	"Phone",
	"Wasp",
	"Space",
	"Hyena",
	"King",
	"Queen",
	"Pig",
	"Nut",
];
