import React from "react";

const BACKGROUND_IMAGE_STYLE = {
	backgroundImage: 'url("/images/backgrounds/bg-pattern-pages.svg")',
	backgroundSize: "1340px 1060px",
	backgroundPosition: "center top",
};

export default function BackgroundImageWrapper({ children }) {
	return (
		<>
			<div
				className="h-full absolute top-0 left-0 w-full opacity-[0.06] pointer-events-none z-0 md:left-[calc(50vw-50%)]"
				style={BACKGROUND_IMAGE_STYLE}
			/>
			<div className="z-1 relative flex flex-col w-full h-full p-0 m-0 overflow-x-hidden">{children}</div>
		</>
	);
}
