import { isUsernameAvailable } from "@/api/helpers";
import { MAX_USER_NAME_LENGTH, MIN_USER_NAME_LENGTH, USER_NAME_REGEX } from "@/app-constants.mjs";
import trans from "@/helpers/trans";
import isString from "lodash/isString";
import Input from "./Input";

export default function UsernameInput({ referenceValue, setState, onChange, onStoppedTyping, ...props }) {
	return (
		<Input
			placeholder={trans("User name")}
			autoComplete="nickname"
			onChange={(ev) => {
				setState({ message: "", valid: undefined, available: undefined });
				if (onChange) {
					onChange(ev);
				}
			}}
			maxLength={MAX_USER_NAME_LENGTH}
			onStoppedTyping={async (ev) => {
				if (referenceValue && ev.target.value.toLowerCase() === referenceValue.toLowerCase()) {
					setState({ message: "", valid: true, available: true });
					return;
				}

				const { message, valid } = checkUsernameValidity(ev.target.value);
				if (valid) {
					setState({ message: trans("Checking availability..."), valid, available: undefined });
				} else {
					setState({ message, valid, available: undefined });
					return;
				}

				const available = await isUsernameAvailable(ev.target.value);
				setState({
					message: available ? trans("Name is available") : trans("Name is not available"),
					valid,
					available,
				});

				if (onStoppedTyping) {
					onStoppedTyping(ev);
				}
			}}
			{...props}
		/>
	);
}

function checkUsernameValidity(username) {
	// This function checks statically whether a username complies with rules
	if (!username || !isString(username)) {
		return { valid: false, message: "Name must not be empty", available: undefined };
	} else if (username.length < MIN_USER_NAME_LENGTH) {
		return {
			valid: false,
			message: trans("Name must be %d chars or more", MIN_USER_NAME_LENGTH),
			available: undefined,
		};
	} else if (username.length > MAX_USER_NAME_LENGTH) {
		return {
			valid: false,
			message: trans("Name must not exceed %d chars", MAX_USER_NAME_LENGTH),
			available: undefined,
		};
	} else if (!USER_NAME_REGEX.test(username)) {
		return { valid: false, message: trans("Only alphanumeric characters allowed"), available: undefined };
	} else {
		return { valid: true, message: "", available: undefined };
	}
}
