import React, { useState, useEffect, useRef, useCallback, forwardRef } from "react";
import useForwardRef from "@/hooks/useForwardRef";

import CheckIcon from "@/images/icons/check.svg";
import CircleIcon from "@/images/icons/circle.svg";

import { tailwindCascade } from "@/helpers/tailwindCascade";

export default forwardRef(function Checkbox(
	{
		children,
		className,
		checked,
		alignText = "right",
		onChange,
		type = "checkbox",
		disabled,
		dark = false,
		...props
	},
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);

	const [isChecked, setIsChecked] = useState(checked);
	useEffect(() => void (onChange && onChange(isChecked)), [isChecked]);

	useEffect(() => {
		if (ref.current) {
			ref.current.checked = checked;
		}
		setIsChecked(checked);
	}, [checked, ref]);

	useEffect(() => {
		const onChange = () => {
			if (ref.current) {
				if (type === "radio" && ref.current.name) {
					ref.current.removeEventListener("change", onChange);

					const elements = (ref.current.closest("form") || document).querySelectorAll(
						`input[type="radio"][name="${ref.current.name}"]`
					);
					elements.forEach((element) => {
						if (element !== ref.current) {
							element.dispatchEvent(new Event("change"));
						} else {
							setIsChecked(ref.current ? !!ref.current.checked : false);
						}
					});

					ref.current.addEventListener("change", onChange);
				} else {
					setIsChecked(ref.current ? !!ref.current.checked : false);
				}
			}
		};

		if (ref.current) {
			ref.current.addEventListener("change", onChange);
			onChange(); // Initial
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener("change", onChange);
			}
		};
	}, [ref, type]);

	const alignTextRight = alignText === "right";

	return (
		<div className={`${disabled ? "opacity-50" : "opacity-100"}`}>
			<label className={tailwindCascade("relative", "block", className)}>
				<input
					disabled={disabled}
					ref={ref}
					type={type}
					className="absolute opacity-0"
					defaultChecked={isChecked}
					{...props}
				/>
				<div
					className={`flex ${alignTextRight ? "flex-row" : "flex-row-reverse"} ${
						alignTextRight ? "" : "space-x-reverse"
					} items-center space-x-2 cursor-pointer select-none`}
				>
					{type === "radio" ? (
						<div className="bg-cyan z-1 relative flex flex-col items-center justify-center w-6 h-6 overflow-hidden text-white rounded-full">
							{isChecked && <CircleIcon className="w-4 h-4" />}
						</div>
					) : (
						<div
							className={`bg-opacity-20 w-4 h-4 overflow-hidden ${
								dark ? "bg-black" : "bg-white"
							} rounded-sm`}
						>
							{isChecked && <CheckIcon className="w-3 h-3 m-0.5" />}
						</div>
					)}
					<div className="text-sm">{children}</div>
				</div>
			</label>
		</div>
	);
});
