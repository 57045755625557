import React, { useState, useCallback, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import sample from "lodash/sample";

import { register } from "@/api/auth";
import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import Button from "@/components/interactives/Button";
import Input from "@/components/interactives/Input";

import Checkbox from "@/components/interactives/Checkbox";
import UsernameInput from "@/components/interactives/UsernameInput";

import BackgroundImageWrapper from "@/components/BackgroundImageWrapper";

import { checkName } from "@/api/user";
import { firstNames, lastNames } from "@/data/names";

import WizardIcon from "@/images/icons/icon-effect.svg";
import CrossIcon from "@/images/icons/icon-crosscircle.svg";

import { MIN_PASSWORD_LENGTH, MAX_USER_NAME_LENGTH, MIN_USER_NAME_LENGTH, MAX_EMAIL_LENGTH } from "@/app-constants.mjs";
import { ContinueWithGoogleButton } from "@/components/interactives/SignUpSignIn";

const RELAXED_EMAIL_REGEX = /^\S+@\S+$/;

export default function CreatePage({ className, onCancel = null }) {
	return (
		<BackgroundImageWrapper>
			<div
				className={tailwindCascade(
					"bg-black-10",
					"sm:rounded-xl",
					"sm:h-auto",
					"sm:mt-8",
					"sm:px-8",
					"text-black-50",
					"container",
					"self-center",
					"h-full",
					"max-w-screen-sm",
					"px-4",
					"py-10",
					"mx-auto",
					"text-black-50",
					className
				)}
			>
				<div className="justify-evenly flex flex-col w-full gap-12">
					<div className="text-2xl font-bold text-center text-black">Create account</div>
					<ContinueWithGoogleButton />
					<CreateAccountForm />
					<div className="md:mt-14 mt-8 font-bold text-center">
						{trans("Got account? ")}
						<Link legacyBehavior href="/user/login/" prefetch={false}>
							<a>
								<u>{trans("Sign in here")}</u>
							</a>
						</Link>
					</div>
				</div>

				{onCancel && (
					<Button
						className="focus-visible:outline-white mx-auto mt-8 text-black"
						color="white"
						onClick={() => void onCancel()}
					>
						{trans("Cancel")}
					</Button>
				)}
			</div>
		</BackgroundImageWrapper>
	);
}

export function CreateAccountForm({ userName }) {
	const router = useRouter();

	const [values, setValues] = useState({
		name: userName ? userName : "",
		email: "",
		password: "",
	});

	const passwordInputRef = useRef(null);
	const emailInputRef = useRef(null);

	const uniqueUsernameId = "create_username";
	const uniquePasswordId = "create_password";
	const [errorMessage, setErrorMessage] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [inputMode, setInputMode] = useState("password");

	const [usernameState, setUsernameState] = useState({
		valid: false,
		available: undefined,
		message: "",
	});

	const [emailError, setEmailError] = useState(null);
	const [usernameError, setUsernameError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);

	const updateEmailError = useCallback(() => {
		const email = emailInputRef.current.value;
		if (!email) {
			setEmailError(trans("Required field"));
			return false;
		} else if (!RELAXED_EMAIL_REGEX.test(email)) {
			setEmailError(trans("Not a valid e-mail address"));
			return false;
		} else {
			setEmailError("");
			return true;
		}
	}, []);

	useEffect(() => {
		if (usernameState.valid !== true || usernameState.available !== true) {
			setUsernameError(usernameState.message);
		} else {
			setUsernameError("");
		}
	}, [usernameState, usernameState.available, usernameState.message, usernameState.valid, values.name]);

	const updatePasswordError = useCallback(() => {
		const password = passwordInputRef.current.value;

		if (!password) {
			setPasswordError(trans("Required field"));
			return false;
		} else if (password.length < MIN_PASSWORD_LENGTH) {
			setPasswordError(trans("Password needs to be at least %d characters.", MIN_PASSWORD_LENGTH));
			return false;
		} else {
			setPasswordError("");
			return true;
		}
	}, []);

	const onSubmit = useCallback(
		async (event) => {
			event.preventDefault();

			const emailOK = updateEmailError();
			const usernameOK = usernameState.valid === true && usernameState.available === true;
			const passwordOK = updatePasswordError();

			if (!emailOK || !usernameOK || !passwordOK) {
				return;
			}

			setIsSubmitting(true);

			try {
				const result = await checkName(values.name);

				if (result.isValid === false) {
					setUsernameError(
						trans(
							"Username must be between %d and %d characters and consist of only aphanumeric characters.",
							MIN_USER_NAME_LENGTH,
							MAX_USER_NAME_LENGTH
						)
					);
				} else if (result.isAvailable === false) {
					setUsernameError(trans("Username has already been taken, please try another one."));
				} else {
					let user = null;
					let success = false;

					try {
						user = await register(values.name, values.email, values.password);
						success = true;
					} catch (error) {
						console.error(error);
						setEmailError(trans("Invalid email address or account exists."));
					}

					if (success) {
						router.push("/user/email/verify/sent/");
					}
				}
			} catch {
				setErrorMessage(trans("An error has occured, please try again later."));
			} finally {
				setIsSubmitting(false);
			}
		},
		[
			router,
			updateEmailError,
			updatePasswordError,
			usernameState.available,
			usernameState.valid,
			values.email,
			values.name,
			values.password,
		]
	);

	const onChange = (event) => {
		const { name, value } = event.target;
		setValues({ ...values, [name]: value });
	};

	const onNameWizard = () => {
		const firstName = sample(firstNames);
		const lastName = sample(lastNames);

		const newName = firstName + lastName + Math.floor(Math.random() * 1000);

		setValues({ ...values, name: newName });
	};

	const terms = (
		// eslint-disable-next-line @next/next/no-html-link-for-pages
		<a className="underline" href="/terms/" target="_blank" rel="noreferrer">
			Terms and Conditions
		</a>
	);

	const privacy = (
		// eslint-disable-next-line @next/next/no-html-link-for-pages
		<a className="underline" href="/privacy/" target="_blank" rel="noreferrer">
			Privacy Policy
		</a>
	);

	return (
		<form action="." onSubmit={onSubmit} className="block">
			{errorMessage && (
				<div className="bg-pink flex flex-row px-4 py-2 mb-4 space-x-1 text-lg font-bold rounded-full">
					<CrossIcon className="w-8 h-8 -mt-0.5" />
					<p>{trans(errorMessage)}</p>
				</div>
			)}
			<div className="font-bold">
				<label>
					<div>{trans("Create account with email")}</div>
					<Input
						ref={emailInputRef}
						className="mt-2 mb-1"
						placeholder="Email"
						name="email"
						type="email"
						inputMode="email"
						autoComplete="email"
						value={values.email}
						onChange={(ev) => {
							setEmailError("");
							onChange(ev);
						}}
						onStoppedTyping={() => void updateEmailError()}
						maxLength={MAX_EMAIL_LENGTH}
					/>
					{emailError ? <p className="text-black">{emailError}</p> : <p>&nbsp;</p>}
				</label>

				<label htmlFor={uniqueUsernameId}>{trans("Username")}</label>
				<div className="flex flex-row items-center space-x-2">
					{/* <Input
		className="mt-2 mb-1"
		placeholder="Username"
		id={uniqueUsernameId}
		name="name"
		type="text"
		autoComplete="off"
		autoCorrect="off"
		autoCapitalize="none"
		spellCheck="false"
		maxLength={MAX_USER_NAME_LENGTH}
		value={values.name}
		onChange={onChange}
	/> */}
					<UsernameInput
						className="mt-2 mb-1"
						placeholder="Username"
						id={uniqueUsernameId}
						name="name"
						type="text"
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="none"
						spellCheck="false"
						maxLength={MAX_USER_NAME_LENGTH}
						value={values.name}
						onChange={onChange}
						setState={setUsernameState}
					/>
					{/* <button
						type="button"
						className="bg-opacity-20 flex -mb-1 bg-black rounded-lg"
						onClick={onNameWizard}
					>
						<WizardIcon className="w-12 h-12" />
					</button> */}
				</div>
				{usernameError ? <p className="text-black">{usernameError}</p> : <p>&nbsp;</p>}
				<div className="flex flex-row justify-between mb-1">
					<div className="flex flex-row space-x-2">
						<label htmlFor={uniquePasswordId}>{trans("Password")}</label>
					</div>

					<Checkbox
						alignText="left"
						onChange={(checked) => {
							if (checked) {
								setInputMode("text");
							} else {
								setInputMode("password");
							}
						}}
						id="show"
					>
						Show
					</Checkbox>
				</div>
				<Input
					ref={passwordInputRef}
					className="mb-1"
					id={uniquePasswordId}
					placeholder="Password"
					name="password"
					type={inputMode}
					autoComplete="current-password"
					value={values.password}
					onChange={(ev) => {
						setPasswordError("");
						onChange(ev);
					}}
					onStoppedTyping={() => void updatePasswordError()}
				/>
				{passwordError ? <p className="text-black">{passwordError}</p> : <p>&nbsp;</p>}
			</div>

			<div className="w-full mt-4">
				<Button
					className="disabled:opacity-50 disabled:cursor-not-allowed w-full text-white"
					disabled={isSubmitting}
					type="submit"
					color="green-light"
				>
					{trans("Create Account")}
				</Button>
			</div>

			<div className="w-full mt-8 text-center">
				By signing up, you accept our {terms} and {privacy}.
			</div>
		</form>
	);
}
