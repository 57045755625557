import React, { useState, useCallback } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import { login } from "@/api/auth";
import trans from "@/helpers/trans";

import BackgroundImageWrapper from "@/components/BackgroundImageWrapper";

import Button from "@/components/interactives/Button";
import Input from "@/components/interactives/Input";

import Checkbox from "@/components/interactives/Checkbox";
import { ContinueWithGoogleButton } from "@/components/interactives/SignUpSignIn";

export default function LoginPage({ ...props }) {
	const router = useRouter();

	return (
		<BackgroundImageWrapper>
			<div className="w-full h-full overflow-hidden">
				<div className="bg-black-10 sm:rounded-xl sm:h-auto sm:mt-8 sm:px-8 text-black-50 container flex flex-col items-stretch self-center h-full max-w-screen-sm gap-4 px-4 pt-4 pb-10 mx-auto">
					<p className="pt-4 pb-8 text-2xl font-bold text-center text-black">{trans("Sign in")}</p>
					<ContinueWithGoogleButton />
					<div>
						<div className="mb-1 font-bold">{trans("Or sign in with email")}</div>
						<LoginForm onSuccess={() => void router.push("/")} />
					</div>
					<div className="justify-evenly md:flex-row md:mt-16 md:space-y-0 flex flex-col w-full mt-8 space-y-4 font-bold text-center">
						<div>
							{trans("No account? ")}
							<Link legacyBehavior href="/user/create/" prefetch={false}>
								<a>
									<u>{trans("Create here")}</u>
								</a>
							</Link>
						</div>
						<div>
							<Link legacyBehavior href="/user/password/forgot/" prefetch={false}>
								<a>
									<u>{trans("Forgot password?")}</u>
								</a>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</BackgroundImageWrapper>
	);
}
export function LoginForm({ onSuccess, ...props }) {
	const [values, setValues] = useState({
		email: "",
		password: "",
	});

	const uniquePasswordId = "login_password";
	const [errorMessage, setErrorMessage] = useState("");
	const [inputMode, setInputMode] = useState("password");
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = useCallback(
		async (event) => {
			event.preventDefault();

			setIsSubmitting(true);

			let auth = null;
			let success = false;
			try {
				auth = await login(values.email, values.password);
				success = true;
			} catch (error) {
				console.error(error);
				setErrorMessage("Invalid username or password");
			}

			setIsSubmitting(false);

			if (success && onSuccess) {
				onSuccess(true);
			}
		},
		[values.email, values.password, onSuccess]
	);

	const onChange = (event) => {
		const { name, value } = event.target;
		setValues({ ...values, [name]: value });
	};

	return (
		<form action="." onSubmit={onSubmit} className="flex flex-col w-full gap-4 m-auto">
			<div className="flex flex-col gap-1 font-bold">
				<label>
					{/* <div className="mb-1">{props.emailLabel || trans("Or sign in with email")}</div> */}
					<Input
						name="email"
						autoComplete="email"
						inputMode="email"
						placeholder={trans("Email")}
						type="email"
						value={values.email}
						onChange={onChange}
					/>
				</label>

				<div className="flex flex-row justify-between">
					<Checkbox
						alignText="left"
						onChange={(checked) => {
							if (checked) {
								setInputMode("text");
							} else {
								setInputMode("password");
							}
						}}
						id="show"
						tabIndex="-1"
						dark={true}
					>
						Show
					</Checkbox>
				</div>

				<Input
					id={uniquePasswordId}
					name="password"
					autoComplete="current-password"
					placeholder={trans("Password")}
					type={inputMode}
					value={values.password}
					onChange={onChange}
				/>
			</div>

			<div className="flex justify-end">
				<Button
					className="disabled:opacity-50 disabled:cursor-not-allowed focus-visible:outline-white w-full text-white"
					disabled={isSubmitting}
					type="submit"
					color="green-light"
				>
					{trans("Sign in")}
				</Button>
			</div>

			<div>
				<p className="mt-3 text-xl text-center text-black">{errorMessage}</p>
			</div>
		</form>
	);
}
