import React, { forwardRef, useEffect, useState, useRef, useCallback } from "react";
import isBoolean from "lodash/isBoolean";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import isColor from "@/helpers/isColor";
import useRefMounted from "@/hooks/useRefMounted";

const INPUT_RADIUS = 12;
const INPUT_BORDER_RADIUS = 16;

export default forwardRef(function Input(
	{
		type,
		roundedLeft = true,
		roundedRight = true,
		border = false,
		inputMode = "text",
		children,
		className,
		onBlur = (ev) => {},
		onChange = (ev) => {},
		onFocus = (ev) => {},
		onStoppedTyping = (ev) => {},
		setValue,
		style,
		...props
	},
	ref
) {
	const [, mountedRef] = useRefMounted();

	const borderRadiusLeft = roundedLeft ? (border ? INPUT_BORDER_RADIUS : INPUT_RADIUS) : 0;
	const borderRadiusRight = roundedRight ? (border ? INPUT_BORDER_RADIUS : INPUT_RADIUS) : 0;

	const borderColor = isBoolean(border) ? "#000" : isColor(border) ? border : "#000";

	const [isTyping, setIsTyping] = useState(false);

	const timeoutRef = useRef(null);
	const cancelTimeout = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
	}, []);

	useEffect(() => {
		return void cancelTimeout();
	}, [cancelTimeout]);

	return (
		<input
			ref={ref}
			type={type || "text"}
			inputMode={inputMode}
			className={tailwindCascade(
				"font-medium",
				"px-4",
				"py-3",
				"mb-3",
				"w-full",
				"text-base",
				"text-black",
				"placeholder-black",
				"placeholder-opacity-50",
				"bg-white",
				"focus:placeholder-opacity-0",
				"text-left",
				"shadow-inner-hard-1",
				"disabled:brightness-75",
				"hover:bg-white-hover",
				"focus:bg-white",
				className
			)}
			style={{
				border: border ? `0.25rem solid ${borderColor}` : "none",
				borderRadius: border
					? `${borderRadiusLeft / 16}rem ${borderRadiusRight / 16}rem ${borderRadiusRight / 16}rem ${
							borderRadiusLeft / 16
					  }rem`
					: `${(borderRadiusLeft - 4) / 16}rem ${(borderRadiusRight - 4) / 16}rem ${
							(borderRadiusRight - 4) / 16
					  }rem ${(borderRadiusLeft - 4) / 16}rem`,
				...style,
			}}
			onChange={(ev) => {
				cancelTimeout();

				if (setValue) {
					setValue(ev.target.value);
				}

				setIsTyping(true);

				timeoutRef.current = setTimeout(() => {
					if (mountedRef.current) {
						setIsTyping(false);
						onStoppedTyping(ev);
					}
				}, 500);

				onChange(ev);
			}}
			onBlur={(ev) => {
				cancelTimeout();

				if (isTyping) {
					setIsTyping(false);
					onStoppedTyping(ev);
				}

				onBlur(ev);
			}}
			onFocus={(ev) => {
				cancelTimeout();

				setIsTyping(false);

				onFocus(ev);
			}}
			{...props}
		/>
	);
});
