import React, { useState, useMemo, useCallback } from "react";

import Modal from "@/components/Modal";
import Button from "@/components/interactives/Button";
import { CreateAccountForm } from "@/components/pages/CreatePage";
import { LoginForm } from "@/components/pages/LoginPage";

import GoogleLogo from "@/images/icons/logo-google.svg";

import { continueAsGuest } from "@/api/auth";

import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { googleAnalyticsEvent } from "@/helpers/gtag";

import { GOOGLE_AUTH_URL } from "@/constants";

export default function SignUpSignIn({ className, onCancel, showContinueAsGuest = true, ...props }) {
	const MODE_GOOGLE = 0;
	const MODE_CREATE_ACCOUNT = 1;
	const MODE_SIGN_IN = 2;

	const [mode, setMode] = useState(MODE_GOOGLE);

	const onClickContinueAsGuest = useCallback(() => {
		continueAsGuest();
		if (onCancel) {
			onCancel(true);
		}
		googleAnalyticsEvent("quiz_continue_as_guest", "guest");
	}, [onCancel]);

	const onClickCreateAccount = useCallback(() => void setMode(MODE_CREATE_ACCOUNT), []);
	const onClickSignIn = useCallback(() => void setMode(MODE_SIGN_IN), []);
	const onClickCancel = useCallback(() => void setMode(MODE_GOOGLE), []);

	const showCancelButton = useMemo(() => !!onCancel, [onCancel]);
	const onCancelDontPlayQuizOnExit = useCallback(() => {
		if (onCancel) {
			onCancel(false);
		}
	}, [onCancel]);

	return (
		<Modal className={tailwindCascade("text-white", className)} onCancel={onCancel}>
			<div className="justify-evenly bg-purple-light rounded-xl flex flex-col items-center w-full max-w-md gap-12 p-8">
				{mode === MODE_GOOGLE && (
					<>
						<img
							src="/images/logo/quiz-multicolor.svg"
							width="192"
							height="52"
							alt={trans("Quiz.com")}
							draggable={false}
							className="block w-48 h-auto"
						/>
						<ContinueWithGoogleButton />
						{showContinueAsGuest && (
							<button className="underline" onClick={onClickContinueAsGuest}>
								{trans("Continue as guest")}
							</button>
						)}
						<div className="justify-evenly flex flex-row w-full">
							<button className="underline" onClick={onClickCreateAccount}>
								{trans("Create account")}
							</button>
							<button className="underline" onClick={onClickSignIn}>
								{trans("Sign in")}
							</button>
							{showCancelButton && (
								<button className="underline" onClick={onCancelDontPlayQuizOnExit}>
									{trans("Cancel")}
								</button>
							)}
						</div>
					</>
				)}
				{mode === MODE_SIGN_IN && (
					<>
						<p className="text-2xl font-bold">{trans("Sign in")}</p>
						<div className="flex flex-col justify-center items-stretch w-full">
							<ContinueWithGoogleButton />
						</div>
						<div className="w-full">
							<div className="mb-1 font-bold">{trans("Or sign in with email")}</div>
							<LoginForm onSuccess={onCancel} />
						</div>
						<button className="underline" onClick={onClickCancel}>
							{trans("Cancel")}
						</button>
					</>
				)}
				{mode === MODE_CREATE_ACCOUNT && (
					<>
						<p className="text-2xl font-bold">{trans("Sign up")}</p>
						<CreateAccountForm />
						<button className="underline" onClick={onClickCancel}>
							{trans("Cancel")}
						</button>
					</>
				)}
			</div>
		</Modal>
	);
}

export function ContinueWithGoogleButton({ children }) {
	const onClickGoogle = () => {
		if (typeof window !== "undefined") {
			window.location = GOOGLE_AUTH_URL;
		}
	};

	return (
		<Button
			className="disabled:opacity-50 disabled:cursor-not-allowed focus-visible:outline-white text-center text-black"
			color="white"
			icon={<GoogleLogo className="w-8 h-8" />}
			onClick={onClickGoogle}
		>
			{children ? children : trans("Continue with Google")}
		</Button>
	);
}
